import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path: '/news/:postId',
    name: 'editnews',
    component: () => import(/* webpackChunkName: "editnews" */ '../views/NewsEdit.vue'),
  },
  {
    path: '/student',
    name: 'student',
    component: () => import(/* webpackChunkName: "student" */ '../views/StudentView.vue')
  },
  {
    path: '/student/:postId',
    name: 'editstudent',
    component: () => import('../views/StudentEdit.vue')
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: () => import(/* webpackChunkName: "teacher" */ '../views/TeacherView.vue')
  },
  {
    path: '/teacher/:teacherId',
    name: 'editteacher',
    component: () => import(/* webpackChunkName: "editnews" */ '../views/TeacherEdit.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if(to.matched.some((record) => record.meta.requiresAuth)){
    if(await getCurrentUser()) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});
export default router
