import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from "./firebase/firebase";
import { createI18n } from "vue-i18n";


const i18n = createI18n({
  locale: "Uzb",
  messages: {
    Uzb: {
      logo: "Denov Abu Ali ibn Sino nomidagi Jamoat salomatligi texnikumi",
      title: "Xush kelibsiz!",
      more: "Batafsil",
      home: "Bosh sahifa",
      new: "Yangiliklar",
      newb: "Texnikumda o'tkazilgan Referendum tadbiridan lavhalar",
      fac: "Fakultetlar",
      tech: "O'qituvchilar",
      about: "Biz haqimizda",
      school: "Maktab haqida",
      call: "Bog'lanish",
      locl: "Bizning manzil",
      loc: "Surxondaryo viloyati, Denov tumani, Yangiariq mahallasi, Kelajagi buyuk ko'chasi, 2-uy",
      page: "Sahifalar",
      soical: "Ijtimoiy tarmoqlar",
      ins: "Instagram",
      facb: "Facebook",
      youtobe: "Youtube",
      tel: "Telegram",
      end: "Bitiruvchilar",
      study: "O'quvchilar",
      name:"Nomi",
      change:"O`zgartirish",
      num:"Soni",
      back:"Orqaga",
      enter:"Kirish",
      kvota:"Har yilgi kvotalar",
      tnum:"ta talaba",
      photo:"Rasm tanlang:",
      create:"Yaratish",
      loading:"Yuklanish",
      add:"O`qituvchi qo`shish",
      loginPlaceHolder: "Loginni kiriting",
      passPlaceHolder: "Parolni kiriting",
    },
    Eng: {
      logo: "Denov Abu Ali ibn Sino nomidagi Jamoat salomatligi texnikumi",
      title: "Welcome!",
      more: "More",
      home: "Homepage",
      new: "News",
      newb: "Films from the Referendum event held at the Technikum",
      fac: "Faculties",
      tech: "Teachers",
      about: "About Us",
      school: "About School",
      call: "Contact",
      locl: "Our Address",
      loc: "Surkhandarya region, Denov district, Yangariq neighborhood, Kelajagi buyuk street, 2 house",
      page: "Pages",
      soical: "Social networks",
      ins: "Instagram",
      facb: "Facebook",
      youtobe: "Youtube",
      tel: "Telegram",
      end: "Graduates",
      study: "Students",
      name:"Name",
      change:"Change",
      num:"Number",
      back:"Back",
      enter:"Enter",
      kvota:"Annual quotas",
      tnum:"a student ",
      photo:"Select photo:",
      create:"Create",
      loading:"Loading",
      add:"Add a teacher",
      loginPlaceHolder: "Enter  login",
      passPlaceHolder: "Enter  password",
    },
    Ru: {
      logo: "Денов Aбу Aли ибн Сино номидаги Жамоат саломатлиги техникуми",
      title: "Добро пожаловать!",
      more: "Подробнее",
      home: "Главная страница",
      new: "Новости",
      newb: "Фильмы с референдума в Техникуме",
      fac: "Факультеты",
      tech: "Учителя",
      about: "O нас",
      school: "Про школу",
      call: "Kонтакт",
      locl: "Наш адрес",
      loc: "Сурхандарьинская область, Деновский район, Янгарикский микрорайон, улица Келажаги буюк, дом 2",
      page: "Страницы",
      soical: "Социальные сети",
      ins: "Инстаграм",
      facb: "Фаcебоок",
      youtobe: "YouTuбe",
      tel: "Телеграмма",
      end: "Выпускники",
      study: "Студенты",
      name:"Имя",
      change:"Изменить",
      num:"Число",
      back:"Назад",
      enter:"Входить",
      kvota:"Годовые квоты",
      tnum:"студент",
      photo:"Выберите изображение:",
      create:"Создавать",
      loading:"Загрузка",
      add:"Добавить учителя",
      loginPlaceHolder: " Введите логин",
      passPlaceHolder: "Введите пароль",
    },
  },
});

createApp(App).use(router).use(i18n).mount("#app");
