<template>
	<div id="progress">
  	<span id="progress-val">&#x1f815;</span>
  </div>
</template>

<script>

let calcScrollValue = () => {
  let scrollProgress = document.getElementById("progress");
  let progressValue = document.getElementById("progress-val");
  let pos = document.documentElement.scrollTop;
  let calcHeight =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  let scrollValue = Math.round((pos * 100) / calcHeight);
  if (pos > 100) {
    scrollProgress.style.display = "grid";
  } else {
    scrollProgress.style.display = "none";
  }
  scrollProgress.addEventListener("click", () => {
    document.documentElement.scrollTop = 0;
  });
  scrollProgress.style.background = `conic-gradient(#111f50 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
};
window.onscroll = calcScrollValue;
window.onload = calcScrollValue;

export default {

}
</script>

<style lang="scss" scoped>
#progress {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 50px;
    height: 50px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 15;

    #progress-val {
      display: block;
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      background: #fff;
      display: grid;
      place-items: center;
      border-radius: 50%;
      font-size: 25px;
      color: #001a2e;
    }
  }
</style>