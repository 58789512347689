// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuVaSCS8c5nsTHgHALa0-qPvlwO_DkY24",
  authDomain: "medical-school-4fcdd.firebaseapp.com",
  projectId: "medical-school-4fcdd",
  storageBucket: "medical-school-4fcdd.appspot.com",
  messagingSenderId: "586212664694",
  appId: "1:586212664694:web:63f7ffaaa740db25ab0657"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAqqCTNj-RZcvqDRAN1xo5nMhwl8h_hQO8",
//   authDomain: "kasb-hunar-maktabi.firebaseapp.com",
//   projectId: "kasb-hunar-maktabi",
//   storageBucket: "kasb-hunar-maktabi.appspot.com",
//   messagingSenderId: "616695050204",
//   appId: "1:616695050204:web:a6b87a584d949945ef6919",
//   measurementId: "G-5LG0T6CG0B"
// };
// const storage = getStorage()
// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const newsColRef = collection(db, "news");
const teachersColRef = collection(db, "teachers");
const statusColRef = collection(db, "students");

export default {  statusColRef, teachersColRef, newsColRef };