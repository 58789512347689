<template>
  <TheNavbar />
  <TopToScroll />
  <router-view />
  <TheFooter />
</template>

<script>
import TheNavbar from "./components/TheNavbar.vue";
import TopToScroll from "./components/TopToScroll.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  components: {
    TheNavbar,TheFooter,TopToScroll
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	// font-family: 'Dancing Script', cursive;
	// border: 1px solid red;
}

html {
	scroll-behavior: smooth;
}

.container {
  // min-width: 700px;
	// width: 1150px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: content-box;
}

a {
	text-decoration: none;
}
</style>
