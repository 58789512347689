<template>
  <footer>
      <div class="container">
				<div class="f-logo">
					<img src="../assets/logo-brend.png" alt="">
				</div>
				<div class="f-pages">
					<ul>
						<li>{{ $t('page') }}</li>
						<li><router-link to="/">{{ $t('home') }}</router-link></li>
						<li><router-link to="/about">{{ $t('about') }}</router-link></li>
						<li><router-link to="/news">{{ $t('new') }}</router-link></li>
						<li><router-link to="/teacher">{{ $t('fac') }}</router-link></li>
						<li><router-link to="/student">{{ $t('study') }}</router-link></li>
						<li><router-link to="/contact">{{ ('call') }}</router-link></li>
					</ul>
				</div>
				<div class="f-social">
					<ul>
						<li>{{ $t('soical') }}</li>
						<li><router-link to="/">
							<div class="logo-fb"></div>
							<span>{{ $t('facb') }}</span> 
						</router-link></li>
						<li><router-link to="/">
							<div class="logo-yt"></div>
							{{ $t('youtobe') }}
						</router-link></li>
						<li><router-link to="/">
							<div class="logo-tg"></div>
							{{ $t('tel') }}
						</router-link></li>
						<li><router-link to="/">
							<div class="logo-inst"></div>
							{{ $t('ins') }}
						</router-link></li>
					</ul>
				</div>
				<div class="f-location">
					<ul>
						<li>{{ $t('locl') }}</li>
						<li><a href="#">
							{{ $t('loc') }}
						</a></li>
						<li><a href="tel:+998956504590">
							<div class="logo-tel"></div>
							+998 95 650 45 90 
						</a></li>
						<li><a href="#">
							<div class="logo-mail"></div>
							info@medic.com
						</a></li>
					</ul>
				</div>
			</div>
  </footer>
	<div class="brand">© {{$t('logo')}}- {{new Date().getFullYear()}}.</div>
	
</template>

<script>

export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
.brand {
	width: 100%;
	text-align: center;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: hsla(227, 65%, 19%, 0.65);
	// opacity: 0.65;
	background: #F3F3FB;
	padding-bottom: 20px;
}

footer {
	width: 100%;
	height: 325px;
	background: #F3F3FB;
	display: flex;
	// flex-direction: column;

	

	.container {
		min-width: 700px;
		width: 1150px;
		align-self: center;

		display: flex;
		justify-content: space-between;

		.f-logo {
			width: 160px;
			height: 160px;
			align-self: center;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.f-pages {

			ul {
				list-style-type: none;

				li {
					font-style: normal;
					font-weight: 400;  //Poppins
					font-size: 14px;   //Poppins
					line-height: 20px;
					opacity: 0.75;
					margin-bottom: 5px;

					// font-weight: 700;
					// font-size: 18px;

					a {
						color: #111F50;
					}
				}

				li:hover {
					opacity: 1;
				}

				li:first-child {
					margin-bottom: 20px;
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
					color: #111F50;
					opacity: 0.75;
				}

				li:last-child {
					margin-bottom: 0;
				}

			}
		}

		.f-social {

			ul {
				list-style-type: none;

				li {
					font-style: normal;
					font-weight: 400;  //Poppins
					font-size: 14px;   //Poppins
					line-height: 20px;
					opacity: 0.75;
					margin-bottom: 8px;

					// font-weight: 700;
					// font-size: 18px;

					a {
						color: #111F50;
						display: flex;
						// cursor: default;

						.logo-fb {
							width: 21px;
							height: 20px;
							background: url(../assets/fb.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer;
						}

						.logo-yt {
							width: 21px;
							height: 20px;
							background: url(../assets/youtube.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer !important;
						}

						.logo-tg {
							width: 21px;
							height: 20px;
							background: url(../assets/telegram.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer !important;
						}

						.logo-inst {
							width: 21px;
							height: 20px;
							background: url(../assets/instagram.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer !important;
						}

						span {
							cursor: pointer;
						}
					}
				}

				li:hover {
					opacity: 1;
				}

				li:first-child {
					margin-bottom: 20px;
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
					color: #111F50;
					opacity: 0.75;
				}

				li:last-child {
					margin-bottom: 0;
				}

			}
		}

		.f-location {
			max-width: 320px;

			ul {
				list-style-type: none;

				li {
					font-style: normal;
					font-weight: 400;  //Poppins
					font-size: 14px;   //Poppins
					line-height: 20px;
					opacity: 0.75;
					margin-bottom: 15px;

					// font-weight: 700;
					// font-size: 18px;

					a {
						color: #111F50;
						display: flex;
						align-items: center;
						// cursor: default;

						.logo-tel {
							width: 16px;
							height: 16px;
							background: url(../assets/tel.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer;
						}

						.logo-mail {
							width: 18px;
							height: 16px;
							background: url(../assets/mail.png) center / cover no-repeat;
							margin-right: 15px;
							cursor: pointer !important;
						}

						span {
							cursor: pointer;
						}
					}
				}

				li:hover {
					opacity: 1;
				}

				li:first-child {
					margin-bottom: 20px;
					font-style: normal;
					font-weight: 700;
					font-size: 18px;
					line-height: 20px;
					color: #111F50;
					opacity: 0.75;
				}

				li:last-child {
					margin-bottom: 0;
				}

			}
		}
	}

}
</style>